.ant-radio-inner::after {
  top: 11px !important;
  left: 11px !important;
}

.ant-table-column-sorters {
  display: flex !important;
  flex: auto;
  align-items: center !important;
  justify-content: space-between;
}
.ant-table-filter-column {
  display: flex !important;
  justify-content: space-between !important;
  padding-right: 15px;
  padding-left: 10px;
}
.service-table .ant-table-thead {
  height: 39px;
}

.translation-textarea {
  background-color: rgb(240, 242, 245);
  margin: 30px 0px 0 20px;
  min-height: 80px;
  padding: 0px 10px 0 10px;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 600px) {
  .ukraine-container {
    max-width: 100% !important;
    flex: unset !important;
  }

  .translation-textarea {
    margin: 0;
  }
}
